
import get from 'lodash/get'
import { mapState } from 'vuex'
import { showError } from '~/utils'
import DSButton from '~/components/DS/Button/Button.vue'

const Login2FA = () => import('~/components/Login/Login2FA.vue')

export default {
  components: { DSButton, Login2FA },
  props: ['isLogin'],
  data() {
    return {
      credential: {
        loginKey: '',
        password: '',
      },
      loading: false,
      errors: '',
      form: this.$form.createForm(this, { name: 'login' }),
      referral: '',
      isMounted: false,
      turnstileLoaded: false,
      token: false,

      // Challenge
      challengeToken: undefined,
      showOTPModal: false,

      countdownInterval: null,
      isDisableResend: this.isDisableResendState || false,
      remainingTime: 0,
      isSubmitting: false,

      // Turnstile
      shouldRenderTurnstile: true,
      turnstileInstance: null,
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.otp.isLoading,
      showOTPLogin: state => state.otp.showOTPLogin,
      isDisableResendState: state => state.otp.isDisableResend,
    }),
    isLoggedIn() {
      return this.$auth && this.$auth.loggedIn
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.ref) {
        this.referral = this.$route.query.ref
      }
      this.isMounted = true
    })
    this.initializeTurnstile()
  },
  beforeDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval)
    }
  },
  methods: {
    validatePassword(rule, value, callback) {
      let alertMessage

      if (value.length < 8) {
        alertMessage = `${this.$t('password')} ${this.$t('passwordPlaceholder')}`
      }

      callback(alertMessage)
    },
    validateForm() {
      return new Promise(resolve => {
        this.form.validateFields((err, values) => {
          if (!err) {
            return resolve([null, values])
          }
          return resolve([err, null])
        })
      })
    },
    async handleSubmit(e) {
      e.preventDefault()
      const [err] = await this.validateForm()
      if (!err) {
        if (this.isLogin) {
          if (this.shouldRenderTurnstile && !this.turnstileInstance) {
            this.renderTurnstile()
          } else {
            this.login()
          }
        } else {
          this.signUp()
        }
      }
    },
    async submit() {
    // Prevent multiple submissions
      if (this.isSubmitting) {
        return
      }

      this.isSubmitting = true // Set the flag to true
      this.loading = true // Optionally set loading state

      try {
        if (this.isLogin) {
          await this.login() // Call the login method
        } else {
          await this.signUp() // Call the sign-up method
        }
        // Handle successful submission (e.g., redirect, show success message)
      } catch (error) {
        // console.error('Submission error:', error)
      // Handle error (e.g., show error message to the user)
      } finally {
        this.isSubmitting = false // Reset the flag after submission
        this.loading = false // Reset loading state
      }
    },
    async login() {
      const [err, values] = await this.validateForm()
      if (!err) {
        const { email: loginKey, password } = values
        const data = {
          loginKey,
          password,
          cf_turnstile_token: this.token,
        }
        this.loading = true
        try {
          this.$store.commit('otp/setIsLoading', true)
          const {
            data: { data: loginResponse },
          } = await this.$auth.loginWith('local', { data })
          const { challengeToken } = loginResponse
          if (challengeToken) {
            this.challengeToken = challengeToken
            this.$message.success('New code has been sent.')
            this.$store.commit('otp/setShowOTPLogin', true)
            this.startCountdown()
            // this.$auth.$storage.setUniversal('challengeToken', challengeToken)
          } else {
            const getPath = localStorage.getItem('loginRedirect') || '/'
            if (typeof window !== 'undefined') {
              // Use window.location.href to reload the page
              window.location.href = getPath
            } else {
              // Fallback to Vue Router push
              this.$router.push(getPath)
            }
          }
        } catch (error) {
          if (error.response) {
            const { errors } = get(error, 'response.data', { message: 'there seems to be an internal error' })
            this.form.setFields({
              password: { errors: [{ message: errors.message }] },
            })

            if (error.response.data.errors.code === 400 && error.response.data.errors.message === 'Too many requests. Please try after 5 minutes') {
              showError(error, error.response.data.errors.message)
            }
          }
        } finally {
          this.$store.commit('otp/setIsLoading', false)
        }
        this.loading = false
        if (!this.showOTPLogin) {
          this.isSubmitting = false
        }
      }
    },
    async signUp() {
      this.errors = ''
      const [err, values] = await this.validateForm()
      if (!err) {
        const { email: loginKey, password } = values
        const data = {
          loginKey,
          password,
          cf_turnstile_token: this.token,
        }
        this.loading = true
        try {
          await this.$axios.post('users/sign-up', { ...values, referralFrom: this.referral })
          await this.$auth.loginWith('local', { data })
          this.$gtm.push({ event: 'sign_up', method: 'password' })
          this.$router.push('/signup/username')
        } catch (error) {
          this.errors = ['Sign up new user failed!']
          if (error.response) {
            const errors = get(error, 'response.data.errors.errors') || {}
            this.errors = Object.values(errors).reduce((prev, cur) => [...prev, ...cur], [])
          }
        }
        this.loading = false
      }
    },
    renderTurnstile() {
      if (!this.shouldRenderTurnstile) {
        return // Skip rendering if the flag is false
      }

      this.loading = true
      // eslint-disable-next-line no-undef
      this.turnstileInstance = turnstile.render('#cf-turnstile-container', {
        sitekey: process.env.NUXT_ENV_CF_TURNSTILE_SITEKEY,
        callback: this.turnstileVerifiedCallback,
        'error-callback': this.turnstileFailedCallback,
      })
    },

    turnstileFailedCallback() {
      this.shouldRenderTurnstile = false
      this.clearTurnstile()

      // Proceed with submission
      if (!this.isSubmitting && !this.isLoggedIn) {
        this.submit()
      }
      this.loading = false
    },
    turnstileVerifiedCallback(token) {
      this.token = token
      this.submit()
    },
    initializeTurnstile() {
      if (typeof window === 'undefined') return

      window.onloadTurnstileCallback = () => {
        this.turnstileLoaded = true
      }

      if (!document.getElementById('turnstile-script')) {
        const script = document.createElement('script')
        script.id = 'turnstile-script'
        script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback'
        script.defer = true
        document.head.appendChild(script)
      }
    },
    clearTurnstile() {
      if (this.turnstileInstance) {
        // Check if the turnstile instance is valid before removing
        try {
        // eslint-disable-next-line no-undef
          turnstile.remove(this.turnstileInstance)
        } catch (error) {
          // console.error('Error removing Turnstile instance:', error)
        }

        this.turnstileInstance = null // Reset the instance
      } else {
        // console.warn('No Turnstile instance to clear.')
      }
      this.loading = false
      this.isSubmitting = false
    },
    handleCloseOTPModal() {
      this.challengeToken = undefined
      this.showOTPModal = false
    },
    async handleSubmitOTP(code) {
      try {
        this.$store.commit('otp/setIsLoading', true)

        const payload = {
          challengeToken: this.challengeToken,
          token: code,
        }

        const response = await this.$axios.post('/users/login/challenge', payload)
        const accessToken = response?.data?.data?.accessToken || undefined

        if (accessToken) {
          try {
            await this.$auth.setUserToken(accessToken)

            // Check if the token was set successfully
            if (this.$auth.loggedIn) {
              this.$refs.otpModal.handleClose()
              // Redirect to the desired page after successful login
              const getPath = localStorage.getItem('loginRedirect') || '/'
              if (typeof window !== 'undefined') {
                // Use window.location.href to reload the page
                window.location.href = getPath
              } else {
                // Fallback to Vue Router push
                this.$router.push(getPath)
              }
              this.$store.commit('otp/setShowOTPLogin', false)
            } else {
              console.log('Failed to set user token')
            }
          } catch (authError) {
            console.error('Authentication error:', authError)
            // Handle authentication error (e.g., show error message to user)
          }
        }

        console.log('response', response)
      } catch (error) {
        this.$refs.otpModal.handleClear()
        this.$store.commit('otp/setShowInvalidCode', true)
      } finally {
        this.$store.commit('otp/setIsLoading', false)
        this.isSubmitting = false
      }
    },
    handleResendOTP() {
      if (this.isDisableResend) return

      this.login()
    },
    startCountdown() {
      this.isDisableResend = true
      this.remainingTime = 60

      if (this.countdownInterval) {
        clearInterval(this.countdownInterval)
      }

      this.countdownInterval = setInterval(() => {
        this.remainingTime -= 1

        if (this.remainingTime <= 0) {
          clearInterval(this.countdownInterval)
          this.countdownInterval = null
          this.isDisableResend = false
          this.remainingTime = 0
        }
      }, 1000)
    },
  },
}
