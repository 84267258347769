
import get from 'lodash/get'
import { mapState } from 'vuex'

export default {
  layout: 'blank',
  props: {
    remainingTime: { type: Number, default: 0 },
    isDisableResend: { type: Boolean, default: false },
  },
  data() {
    return {
      otp: Array(6).fill(''),
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputElements[0].focus()
      this.$store.dispatch('otp/checkLastClickTime')
    })
  },
  computed: {
    ...mapState({
      isLoading: state => state.otp.isLoading,
      showInvalidCode: state => state.otp.showInvalidCode,
      showOTPLogin: state => state.otp.showOTPLogin,
    }),
    userEmail() {
      return get(this.$auth, 'user.email')
    },
  },
  watch: {
    showOTPLogin: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.inputElements[0].focus()
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    setInput(value, index) {
      this.otp.splice(index, 1, value)
      const isLastInput = index === this.otp.length - 1
      const isOTPComplete = this.otp.every(digit => digit !== '')
      if (value && !isLastInput) {
        this.$refs.inputElements[index + 1].focus()
      }
      if (isOTPComplete) {
        this.submitOTP()
      }
    },
    handlePaste(event, index) {
      event.preventDefault()
      const pasteData = event.clipboardData.getData('text').slice(0, 6 - index)
      const pasteArray = pasteData.split('')
      pasteArray.forEach((char, idx) => {
        const inputIndex = index + idx
        if (inputIndex < this.otp.length) {
          this.otp.splice(inputIndex, 1, char)
        }
      })
      this.$nextTick(() => {
        if (index + pasteArray.length < this.$refs.inputElements.length) {
          this.$refs.inputElements[index + pasteArray.length].focus()
        }
      })
      const isOTPComplete = this.otp.every(digit => digit !== '')
      if (isOTPComplete) {
        this.submitOTP()
      }
    },
    handleKeyDown(event, index) {
      if (event.key === 'Backspace' && this.otp[index] === '') {
        if (index > 0) {
          this.$refs.inputElements[index - 1].focus()
        }
      }
    },
    handleClear() {
      this.otp = Array(6).fill('')
      this.$refs.inputElements[0].focus()
    },
    handleClose() {
      this.handleClear()
      this.$store.commit('otp/setShowInvalidCode', false)
      this.$emit('onClose')
    },
    handleResend() {
      if (this.isDisableResend) return

      this.handleClear()
      this.$store.commit('otp/setShowInvalidCode', false)
      this.$emit('onResendOTP')
    },
    submitOTP() {
      const otpValue = this.otp.join('')
      this.$emit('onSubmitOTP', otpValue)
    },
  },
}
